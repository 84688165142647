import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";
import { API, graphqlOperation } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { documentPDF, createQuittance, createPDF } from "../libs/pdfLib";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";
import { deleteTask } from "../graphql/mutations";
import { listTasks } from "../graphql/queries";
import MainFeaturedPost from "./MainFeaturedPost";

import ParticlesBg from 'particles-bg'

import Hero from './Hero';
import Exemplar from './Exemplar';
import Features from './Features';
import About from './About';
import LatestNews from './LatestNews';
import SocialBrand from './SocialBrand';
import Footer from './Footer';


export default function Home() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated, language } = useAppContext();
  const { lastDocumentData, setLastDocumentData } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function onLoad() {
      console.log(lastDocumentData);
      if (!isAuthenticated) {
        return;
      }

      try {
        const notes = await loadNotes();
        setNotes(notes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  //   function loadNotes() {
  //     return API.get("notes", "/notes");
  //   }

  async function deleteNote(id) {
    // return API.del("notes", `/notes/${id}`);
    console.log("here", id);
    await API.graphql(graphqlOperation(deleteTask, { input: { id: id } }));
  }

  async function handleDelete(event, id) {
    event.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }
    setIsDeleting(true);
    try {
      await deleteNote(id);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  async function loadNotes() {
    try {
      // console.log("fetching",user.attributes.sub);
      const todoData = await API.graphql(graphqlOperation(listTasks, {}));
      console.log(todoData.data.listTasks.items);
      //const todos = todoData.data.listTodos.items;
      return todoData.data.listTasks.items;
    } catch (err) {
      console.log("error fetching todos");
      console.log(err);
    }
  }

  async function download(index) {
    console.log("download", notes[index].description);
    const myValues = JSON.parse(notes[index].description);
    const blob = await createPDF(myValues);
    saveAs(blob, "document.pdf");
  }

  async function getInvoice() {
    const newVal = {
      firstName: "Fred",
    };
    const blob = await createPDF(newVal);
    saveAs(blob, "document.pdf");
    setLastDocumentData({ fred: "hello" });
  }

  //   </LinkContainer>
  //   {notes.map(({ noteId, content, createdAt }) => (
  //     <LinkContainer key={noteId} to={`/notes/${noteId}`}>
  //       <ListGroup.Item action>
  //         <span className="font-weight-bold">
  //           {content.trim().split("\n")[0]}
  //         </span>
  //         <br />
  //         <span className="text-muted">
  //           Created: {new Date(createdAt).toLocaleString()}
  //         </span>
  //       </ListGroup.Item>
  //     </LinkContainer>

  function Top() {
    console.log("your language", language);
    let createLabel = "Create a new document";
    if (language === "fr") {
      createLabel = "Creer un nouveau document";
    }
    return (
      <LinkContainer to="/testform">
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold">{createLabel}</span>
        </ListGroup.Item>
      </LinkContainer>
    );
  }

  const mainFeaturedPost = {
    title: "Title of a longer featured blog post",
    description:
      "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
    image: "https://images.unsplash.com/photo-1470252649378-9c29740c9fa8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80",
    imgText: "main image description",
    linkText: "Continue reading…",
  };

  // {Object.keys({"hello":"name"}).map(function (key) {
  function renderNotesList(notes) {
    return (
      <>
        

        <Table striped bordered hover>
          <thead>
            <tr>
              <th></th>
              <th>Description</th>
              <th>Recipient</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {notes.map(({ id, type, description, createdAt }, index) => (
              <tr>
                <td>
                  <Button
                    onClick={() => {
                      download(index);
                    }}
                  >
                    &#8595;
                  </Button>
                </td>
                <td>{type}</td>
                <td>{JSON.parse(description).recipientName}</td>
                <td>{new Date(createdAt).toLocaleString('en-GB').substring(0,10)}</td>
                <td>
                  {" "}
                  <Button
                    variant="danger"
                    onClick={(event) => {
                      handleDelete(event, id);
                    }}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* {notes.map(({ id, description, createdAt }) => (
          <LinkContainer key={id} to={`/notes/${id}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {JSON.parse(description).firstName}
                {JSON.parse(description).address}
              </span>
              <span className="text-muted">
                Created: {new Date(createdAt).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))} */}
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h4>123 codify</h4>
        <MainFeaturedPost post={mainFeaturedPost} />
        <Hero />
      <Exemplar />
      <Features />
      <About />
      <LatestNews />
      <SocialBrand />
      </div>
    );
  }

  function renderNotes() {
    let yourDocumentsLabel = "Your documents";
    if (language === "fr") {
      yourDocumentsLabel = "Vos documents";
    }
    console.log("notes",notes);
    return (
      <div className="notes">
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderNotes() : renderLander()}
      <ParticlesBg type="circle" bg={true} />
    </div>
  );
}
