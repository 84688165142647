import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import MainFeaturedPost from "./MainFeaturedPost";
import JsxParser from 'react-jsx-parser'

// import Markdown from "markdown-to-jsx";
import ReactMarkdown from "react-markdown";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { render } from "react-dom";
import termsFrPath from './blog/blog1.md'

const MyImage = props => {
  // const [fullSize, setFullSize] = useState();
  // const handleClick = () => {
  //   setFullSize(!fullSize);
  // };
  return (
    <img src={props.src} class="img-fluid rounded mx-auto d-block" alt={props.alt}></img>
    // <img src={'screen0.png'} class="img-fluid rounded mx-auto d-block" alt={props.alt}></img>
    // <img
    //   className={fullSize ? "large" : "small"}
    //   alt={props.alt}
    //   src={props.src}
    //   onClick={handleClick}
    // />
  );
};

const components = {
  MainFeaturedPost
}

const renderers = {
  code: ({ language, value }) => {
    // render "jsx" type as self-contained JSX (with only above components exposed)
    if (language === 'jsx') {
      return <JsxParser jsx={value} components={components} />
    }
    // return <SyntaxHighlighter style={dark} language={language} children={value} />
    return <SyntaxHighlighter language={language} children={value} />;
  },
  image: MyImage
};

// import { Header, Text } from "./styles";

const mainFeaturedPost = {
  title: "Title of a longer featured blog post",
  description:
    "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: "https://source.unsplash.com/random",
  imgText: "main image description",
  linkText: "Continue reading…",
};

const markdown2 = `
# Header

<Text>Hello world!</Text>
<a href="https://www.grover.com/de-en" rel="noopener noreferer" target="_blank">Grover site</a>
<small>Sample content borrowed with thanks from [elm-markdown](http://elm-lang.org/examples/markdown) ❤️</small>
<MainFeaturedPost post={{
  title: 'Title of a longer featured blog post',
  description:
    "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: 'https://source.unsplash.com/random',
  imgText: 'main image description',
  linkText: 'Continue reading…',
}} />
`;

const pStyle = {
  // fontSize: '15px',
  // backgroundColor: 'white',
  textAlign: 'justify'
};




export default function Test() {
  const [terms,setTerms] = useState("");



  useEffect(() => {
    fetch(termsFrPath).then((response) => response.text()).then((text) => {
      setTerms(text)
    })
  }, []);


  return (
    <div>
      <div class="jumbotron d-flex align-items-center bg-white">
        <div class="container p-3 my-3 bg-white" style={pStyle}>
           {/* <MainFeaturedPost post={mainFeaturedPost} /> */}
           {/* <ReactMarkdown renderers={renderers} children={markdown} /> */}
           <ReactMarkdown renderers={renderers} source={terms} />
        </div>
      </div>
      {/* <Markdown
        options={{
          overrides: {
              MainFeaturedPost: {
                  component: MainFeaturedPost,
              }
          }
        }}
        // options={{
        //   overrides: { h1: { component: Header }, Text: { component: Text } }
        // }}
      >
        {markdown}
      </Markdown> */}
      <div
        dangerouslySetInnerHTML={{
          __html: "<script>alert(document.cookie);</script>",
        }}
      />
    </div>
  );
}

