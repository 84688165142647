import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Welcome from "./containers/Welcome";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import NewDocument from "./containers/NewDocument";
import Blog from "./containers/Blog";
import ResetPassword from "./containers/ResetPassword";
import TestForm from "./containers/TestForm";
import Test from "./containers/Test";
import MyApi from "./containers/MyApi";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      {/* <Route exact path="/login">
        <Login />
      </Route> */}
      <Route exact path="/welcome">
        <Welcome />
      </Route>
      <Route exact path="/blog">
        <Blog />
      </Route>
      {/* <Route exact path="/signup">
        <Signup />
      </Route> */}
      <Route exact path="/testform">
        <Blog />
      </Route>
      {/* <Route exact path="/notes/new">
        <NewNote />
      </Route> */}
      <Route exact path="/documents/new">
        <NewDocument />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/test">
        <Test />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      {/* <AuthenticatedRoute exact path="/settings">
  <Settings />
</AuthenticatedRoute> */}
      <AuthenticatedRoute exact path="/notes/new">
        <NewNote />
      </AuthenticatedRoute>
      {/* <AuthenticatedRoute exact path="/notes/:id">
        <Notes />
      </AuthenticatedRoute> */}
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/myapi">
        <MyApi />
      </UnauthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
