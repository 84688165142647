import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import axios from "axios";
import * as yup from "yup";
import styled, { css } from 'styled-components';
import Button from "react-bootstrap/Button";
import { Formik, Form, Field, useField, useFormikContext } from "formik";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Welcome() {
  const [signatureX, setSignatureX] = useState(0);
  const [formData, setFormData] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [myData, setMyData] = useState(null);
  const [myDoc, setMyDoc] = useState(null);
  const [count,setCount]= useState(0);
  const myURL =
    "https://www.interieur.gouv.fr/content/download/125622/1005086/file/24-12-2020-attestation-deplacement-du-royaume-uni-vers-la-france.pdf";

  async function loadPDF() {
    try {
      const input = await axios.get(myURL, {
        responseType: "arraybuffer",
      });
      const pdfDoc = await PDFDocument.load(input.data);
      const pages = pdfDoc.getPages();
      setMyDoc(pdfDoc);
      //pages[0].drawText("You can modify PDFs too!");
      const pdfBytes = await pdfDoc.save();
      var blob = new Blob([pdfBytes]);
      var myArrayBuffer = await blob.arrayBuffer();
      // console.log(myArrayBuffer);
      setMyData(myArrayBuffer);
      return myArrayBuffer;
    } catch (err) {
      console.log("error creating todo:", err);
    }
  }

  const updateMousePosition = (ev) => {
    // console.log(ev.clientX, ev.clientY);
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    console.log("in use effect");
    loadPDF();
  }, []);

  useEffect(() => {
    console.log("in other use effect");
    update(count.toString())
  }, [count]);

  // useEffect(() => {
  //   window.addEventListener("mousemove", updateMousePosition);

  //   return () => window.removeEventListener("mousemove", updateMousePosition);
  // }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  async function update(myText) {
    try {
      const pages = myDoc.getPages();
      console.log("in update",myText);

      pages[0].drawText(myText, {
        // x: pages[0].getWidth()*(parseFloat(formData.X)/21),
        x: pages[0].getWidth() /2,
        y: pages[0].getHeight() / 2,
      });
      const pdfBytes = await myDoc.save();
      const blob = new Blob([pdfBytes]);
      const myArrayBuffer = await blob.arrayBuffer();
      // console.log(myArrayBuffer);
      setMyData(myArrayBuffer);
      return myArrayBuffer;
    } catch (err) {
      console.log("error add texte:", err);
    }
  }

  async function addSignature() {
    try {
      const jpgImageBytes = await fetch(
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Dent_du_G%C3%A9ant_depuis_l%27Aiguille_du_Midi.jpg/800px-Dent_du_G%C3%A9ant_depuis_l%27Aiguille_du_Midi.jpg"
      ).then((r) => r.arrayBuffer());
      // .then(async (buffer) => {
      // note this is already an ArrayBuffer
      //console.log(buffer);
      // const jpgImageBytes = new Uint8Array( buffer);
      console.log("**", jpgImageBytes);
      // Embed the JPG image bytes and PNG image bytes
      const jpgImage = await myDoc.embedJpg(jpgImageBytes);
      const jpgDims = jpgImage.scale(0.25);
      // Draw the JPG image in the center of the page
      const pages = myDoc.getPages();
      pages[0].drawImage(jpgImage, {
        x: pages[0].getWidth() / 2 - jpgDims.width / 2,
        y: pages[0].getHeight() / 2 - jpgDims.height / 2,
        width: jpgDims.width,
        height: jpgDims.height,
      });

      pages[0].drawText("You can modify PDFs too!");
      const pdfBytes = await myDoc.save();
      var blob = new Blob([pdfBytes]);
      var myArrayBuffer = await blob.arrayBuffer();
      console.log(myArrayBuffer);
      setMyData(myArrayBuffer);
      return myArrayBuffer;
    } catch (err) {
      console.log("error addisignature:", err);
    }
  }

  const PDFDocumentWrapper = styled.div`
  canvas {
    width: 80% !important;
    height: auto !important;
  }
`;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};


  return (
    <div>
      <button onClick={addSignature}>Add signature</button>

      <Formik
        initialValues={{"X":0}}
        onSubmit={async values => {
          console.log("submitting");
          console.log(values);
          // setFormData(values);
          console.log("count",count)
          setCount(count+1);
          // update(values.X);
        }}
        // validationSchema={yup.object({
        //   firstName: yup.string().required("First Name is required").max(20),
        // })}
      >
        {({ errors, touched }) => (
          <Form>
            <MyTextInput
              label="X pos"
              name="X"
              type="text"
              placeholder="10"
            />
            <div>
            <Button
          class=".btn-md"
          type="submit"
        >
          Update
        </Button>
        <button type="submit">Submit</button>
            {/* <Button class=".btn-md"  type="submit" variant="primary" onClick={() => setDirection('back')}>Back</Button>{' '}
            <Button class=".btn-md"  type="submit" variant="primary" onClick={() => setDirection('forward')}>Continue</Button>{' '} */}
            </div>
          </Form>
        )}
      </Formik>
      <div
      // ref={(el) => {
      //   if (!el) return;
      //   console.log("initial el", el.getBoundingClientRect());
      // }}
    >
      <PDFDocumentWrapper>
      <Document file={myData} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      </PDFDocumentWrapper>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      </div>
    </div>
  );
}
