import React from 'react';
import "./Hero.css";
// import meadow from 'meadow.jpg';

const Hero = () => {
  return (
    <section
      id="hero"
      className="blue lighten-4"
      // style={{ backgroundImage: 'url(' + meadow + ')'}}
    >
      <div className="lander">
        <div>
          <p>We give your business an internet presence</p>
          <h3>
            Develop, Deploy, Done
          </h3>

          <p>
            We build your website using cutting edge frameworks
            <br/>
            entirely customized and made to order
          </p>

          <a>Features</a>
          <a>Portfolio</a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
