// import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import { documentPDF, createQuittance } from "../libs/pdfLib";
import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";
import "./styles.css";
import "./styles-custom.css";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import "./sigCanvas.css";
import useIsMounted from "../useIsMounted";
import { PDFDocument } from "pdf-lib";
import axios from "axios";
import { Document, Page, pdfjs } from "react-pdf";

// import {
//   pdf,
//   Document,
//   Image,
//   Page,
//   Text,
//   View as viewPDF,
//   StyleSheet,
// } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import { API, graphqlOperation } from "aws-amplify";
import { createTask, deleteTask } from "../graphql/mutations";
// import { listTasks } from "../graphql/queries";

const initialState = { name: "", description: "" };

// const styles = StyleSheet.create({
//   container: { flex: 1, justifyContent: "center", padding: 20 },
//   todo: { marginBottom: 15 },
//   input: { height: 50, backgroundColor: "#ddd", marginBottom: 10, padding: 8 },
//   todoName: { fontSize: 18 },
// });

export default function NewDocument() {
  const file = useRef(null);
  const history = useHistory();
  //const [content, setContent] = useState("");
  //const [isLoading, setIsLoading] = useState(false);
  //const [authState, setAuthState] = React.useState();
  // const [user, setUser] = React.useState();
  // const [myText, setMyText] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [imageURL, setImageURL] = useState(""); // create a state that will contain our image url
  const [initValues, setInitValues] = useState({
    firstName: "Fred",
    lastName: "Brown",
    email: "fred.net@outlook.com",
    address: "NW6",
    acceptedTerms: true, // added for our checkbox
    jobType: "", // added for our select
    rent: "100",
  });
  const isMounted = useIsMounted();
  const formRef = useRef();
  const [myData, setMyData] = useState(null);
  const [myData2, setMyData2] = useState("yo");

  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  //**graphql stuff
  const [formState, setFormState] = useState(initialState);
  const [todos, setTodos] = useState([]);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function addTodo() {
    try {
      //const todo = { sub:user.attributes.sub,name:JSON.stringify(initValues)}
      await myprint();
      const todo = { description: JSON.stringify(initValues) };
      setTodos([...todos, todo]);
      setFormState(initialState);
      console.log("TODO",todo);
      await API.graphql(graphqlOperation(createTask, { input: todo }));
      history.push("/");
    } catch (err) {
      console.log("error creating todo:", err);
    }
  }
  //graphql

  const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  const MyCheckbox = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: "checkbox" });
    return (
      <>
        <label className="checkbox">
          <input {...field} {...props} type="checkbox" />
          {children}
        </label>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  // Styled components ....
  const StyledSelect = styled.select`
    color: var(--blue);
  `;

  const StyledErrorMessage = styled.div`
    font-size: 12px;
    color: var(--red-600);
    width: 400px;
    margin-top: 0.25rem;
    &:before {
      content: "❌ ";
      font-size: 10px;
    }
    @media (prefers-color-scheme: dark) {
      color: var(--red-300);
    }
  `;

  const StyledLabel = styled.label`
    margin-top: 1rem;
  `;

  const MySelect = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
        <StyledSelect {...field} {...props} />
        {meta.touched && meta.error ? (
          <StyledErrorMessage>{meta.error}</StyledErrorMessage>
        ) : null}
      </>
    );
  };

  async function getInput() {
    try {
      // const myURL =
      //   "https://www.interieur.gouv.fr/content/download/125622/1005086/file/24-12-2020-attestation-deplacement-du-royaume-uni-vers-la-france.pdf";
      // const input = await axios.get(myURL, {
      //   responseType: "arraybuffer",
      // });
      // const pdfDoc = await PDFDocument.load(input.data);
      // const pdfBytes = await pdfDoc.save();
      // var blob = new Blob([pdfBytes]);

      let temp = initValues;
      temp.imageURL = imageURL;
      const blob = await createQuittance(temp);

      var myArrayBuffer = await blob.arrayBuffer();
      console.log("myarrayBuffer", myArrayBuffer);
      setMyData(myArrayBuffer);
      setMyData2("eni");
      return myArrayBuffer;
    } catch (err) {
      console.log("error creating todo:", err);
    }
  }

  useEffect(() => {
    getInput();
  }, [isFormFilled]);

  const myprint = async () => {
    // PDF Creation
    // const pdfDoc = await PDFDocument.create()
    // const page = pdfDoc.addPage()
    // page.drawText('You can create PDFs!')
    // const pdfBytes = await pdfDoc.save()
    // console.log('PDF',pdfBytes);
    // var blob = new Blob([pdfBytes]);
    // console.log(blob);
    // saveAs(blob, "document.pdf");
    // console.log(blob);

    // try {
    //   const myURL =
    //     "https://www.interieur.gouv.fr/content/download/125622/1005086/file/24-12-2020-attestation-deplacement-du-royaume-uni-vers-la-france.pdf";
    //   const input = await axios.get(myURL, {
    //     responseType: "arraybuffer",
    //   });

    //const pdfDoc = await PDFDocument.load(myURL)
    // const pdfDoc = await PDFDocument.load(input.data);
    // const pages = pdfDoc.getPages();
    // pages[0].drawText("You can modify PDFs too!");
    // const pdfBytes = await pdfDoc.save();
    // var blob = new Blob([pdfBytes]);
    try {
      //const blob = await documentPDF(initValues);
      let temp = initValues;
      temp.imageURL = imageURL;
      const blob = await createQuittance(temp);
      console.log(blob);
      saveAs(blob, "document.pdf");
      console.log(blob);
    } catch (err) {
      console.log("error fetching todos");
      console.log(err);
    }

    // const doc = <documentPDF {...values} />;
  };

  // And now we can use these
  const SignupForm = () => {
    return (
      <>
        <h4>Ma quittance en 1 clic!</h4>
        <Formik
          initialValues={initValues}
          innerRef={formRef}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .max(15, "Must be 15 characters or less")
              .required("Required"),
            lastName: Yup.string()
              .max(20, "Must be 20 characters or less")
              .required("Required"),
            address: Yup.string()
              .max(100, "Must be 100 characters or less")
              .required("Required"),
            email: Yup.string()
              .email("Invalid email addresss`")
              .required("Required"),
            acceptedTerms: Yup.boolean()
              .required("Required")
              .oneOf([true], "You must accept the terms and conditions."),
            jobType: Yup.string()
              // specify the set of valid values for job type
              // @see http://bit.ly/yup-mixed-oneOf
              .oneOf(
                ["Novembre", "Decembre", "product", "other"],
                "Invalid Job Type"
              )
              .required("Required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            //alert(JSON.stringify(values, null, 2));
            //await myprint(values);
            // if (isMounted.current) {
            // setMyText("hello");
            // console.log(myText);
            // } else {
            //   console.log('not omute')
            // }
            await new Promise((r) => setTimeout(r, 500));
            console.log(formRef.current.values);
            setInitValues(formRef.current.values);
            setSubmitting(false);
            setIsFormFilled(true);
          }}
        >
          <Form>
            <MyTextInput
              label="Bailleur"
              name="firstName"
              type="text"
              placeholder="Jane"
            />
            <MyTextInput
              label="Locataire"
              name="lastName"
              type="text"
              placeholder="Doe"
            />
            <MyTextInput
              label="Address"
              name="address"
              type="text"
              placeholder="10 rue Voltaire"
            />
            <MyTextInput
              label="Loyer"
              name="rent"
              type="text"
              placeholder="100"
            />
            <MyTextInput
              label="Email Address"
              name="email"
              type="email"
              placeholder="jane@formik.com"
            />
            <MySelect label="Job Type" name="jobType">
              <option value="Novembre">Novembre</option>
              <option value="Decembre">Decembre</option>
              {/* <option value="product">Product Manager</option>
              <option value="other">Other</option> */}
            </MySelect>
            <MyCheckbox name="acceptedTerms">
              {/* I accept the terms and conditions */}
              J'accepte les conditions.
            </MyCheckbox>

            <button type="submit">Valider</button>
          </Form>
        </Formik>
      </>
    );
  };

  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();

  const save = () => {
    //console.log(formRef.current.values);
    // setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setImageURL(sigCanvas.current.toDataURL("image/png"));
    //setInitValues(formRef.current.values);
  };

  function onDocumentLoadSuccess() {
    console.log("here");
  }

  const PDFDocumentWrapper = styled.div`
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  `;

  return (
    <div className="NewDocument">
      {isFormFilled ? (
        <div>
          <div className="maquittance">
            <h4>Voici votre quittance</h4>
            {/* <p className="text-muted">{JSON.stringify(initValues)}</p> */}
          </div>
          <Popup
            modal
            trigger={<button>Sign</button>}
            closeOnDocumentClick={false}
            contentStyle={{ width: "80%" }}
          >
            {(close) => (
              <>
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{
                    className: "signatureCanvas",
                  }}
                />
                {/* Button to trigger save canvas image */}
                <button onClick={save}>Save</button>
                <button onClick={clear}>Clear</button>
                <button onClick={close}>Close</button>
              </>
            )}
          </Popup>
          <button onClick={addTodo}>SAVE</button>
          <PDFDocumentWrapper>
            <Document file={myData} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={1} />
            </Document>
          </PDFDocumentWrapper>
        </div>
      ) : (
        <SignupForm />
      )}
      <br />
      {imageURL ? (
        <img
          src={imageURL}
          alt="my signature"
          style={{
            display: "block",
            margin: "0 auto",
            border: "1px solid black",
            width: "150px",
          }}
        />
      ) : null}
    </div>
  );
}
