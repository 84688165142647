import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import {
  pdf,
  Document,
  Image,
  Page,
  Text,
  View as viewPDF,
  StyleSheet,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    width: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  todo: { marginBottom: 15 },
  input: {
    border: "none",
    backgroundColor: "#ddd",
    marginBottom: 10,
    padding: 8,
    fontSize: 18,
  },
  todoName: { fontSize: 20, fontWeight: "bold" },
  todoDescription: { marginBottom: 0 },
  page: {
    flexDirection: "column",
  },
  // image: {
  //   width: "20%",
  //   height: "20%",
  //   padding: 10,
  // },
  centerImage: {
    alignItems: "center",
    flexGrow: 1,
  },
  text: {
    width: "100%",
    backgroundColor: "#f0f0f0",
    color: "#212121",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 20,
    marginHorizontal: 10,
    height: 80,
    width:80
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  userContainer: {
    position: "absolute",
    width: 400,
    fontSize: 12,
    bottom: 680,
    left: 400,
    right: 0,
    textAlign: "left",
  },
  recipientContainer: {
    position: "absolute",
    width: 400,
    fontSize: 12,
    bottom: 600,
    left: 400,
    right: 0,
    textAlign: "left",
  },
});

export async function createPDF(values) {
  console.log("CREATEINVOICE",values);
  // values.firstName = "frederic";
  // values.lastName = "brown";
  // values.email = "fred.ne";
  // values.recipientName = "Matt";
  // values.itemCost = "100";
  // values.itemDescription = "Flowers";


  const MyDoc = (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          ~ www.123signature.com ~
        </Text>
        <Text style={styles.title}>INVOICE{'\n\n\n\n\n\n\n\n\n\n'}</Text>
        
        <Text style={styles.text}>Description:{'\n'}
                                  ________________________________________________________________________</Text>
        <Text style={styles.text}>{'\n'}{values.itemDescription}{'                              '}{values.itemCost}{'£\n'}</Text>
        <Text style={styles.text}>________________________________________________________________________</Text>
        
        
        <Text style={styles.text}>{values.address} </Text>
        <Text style={styles.text}>
          Date: {new Date().toISOString().split("T")[0]}
        </Text>
        <Text style={styles.text}>Signature</Text>
        {values.imageURL ? (
          <Image style={styles.image} src={values.imageURL} />
        ) : null}
        <Text style={styles.userContainer}>
          Invoice from:{'\n'}
          {values.firstName}{' '}{values.lastName}{'\n'}
          {values.email}
        </Text>
        <Text style={styles.recipientContainer}>
          Recipient:{'\n'}
          {values.recipientName}
          {values.recipientAddress}
        </Text>
      </Page>
    </Document>
  );

  console.log(MyDoc);

  return pdf(MyDoc).toBlob();
}

export async function createQuittance(values) {
  console.log("CREATEQUITTANCE",values);

  const MyDoc = (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          ~ www.123signature.com ~
        </Text>
        <Text style={styles.title}>QUITTANCE DE LOYER {values.jobType}</Text>
        <Text style={styles.author}>
          Bailleur: {values.firstName}
        </Text>
        <Text style={styles.subtitle}>Adresse du logement:</Text>
        <Text style={styles.text}>{values.address} </Text>
        <Text style={styles.text}>
          Je soussigné {values.firstName} propriétaire du logement désigné
          ci-dessus, déclare avoir reçu de {values.lastName} la somme de{" "}
          {values.rent} euros pour le mois de {values.jobType}
        </Text>
        <Text style={styles.text}>
          Fait le {new Date().toISOString().split("T")[0]}
        </Text>
        <Text style={styles.text}>Signature</Text>
        {values.imageURL ? (
          <Image style={styles.image} src={values.imageURL} />
        ) : null}
        <Text style={styles.pageNumber}>
          Cette quittance annule tous les reçus qui auraient pu être établis
          précédemment en cas de paiement partiel du montant du présent terme.
          Elle est à conserver pendant trois ans par le locataire (article 7-1
          de la loi n° 89-462 du 6 juillet 1989).
        </Text>
        {/* <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        /> */}
      </Page>
    </Document>
  );

  console.log(MyDoc);

  return pdf(MyDoc).toBlob();
}

export async function documentPDF(values) {
  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  // Add a blank page to the document
  const page = pdfDoc.addPage();
  // Get the form so we can add fields to it
  const form = pdfDoc.getForm();

  // Get the width and height of the page
  const { width, height } = page.getSize();

  // Draw a string of text toward the top of the page
  const fontSize = 12;
  const declarationText =
    "Je soussigné " +
    values.firstName +
    " propriétaire du logement désigné ci-dessus, déclare avoir reçu de " +
    values.lastName +
    " la somme de " +
    values.rent +
    "euros pour le mois de " +
    values.jobType;

  page.drawText("Created with 123signature.com", {
    x: 50,
    y: height - 4 * fontSize,
    size: 10,
    font: timesRomanFont,
    color: rgb(0, 0.53, 0.71),
  });

  page.drawText("QUITTANCE DE LOYER " + values.jobType, {
    x: 50,
    y: height - 100,
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
  });

  const superheroField = form.createTextField("favorite.superhero");
  superheroField.setText(declarationText + declarationText);
  superheroField.addToPage(page, { x: 55, y: 640 });

  page.drawText(declarationText, {
    x: 50,
    y: height / 2,
    size: fontSize,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
  });

  const pdfBytes = await pdfDoc.save();
  console.log("PDF", pdfBytes);
  var blob = new Blob([pdfBytes]);
  console.log(blob);

  return blob;
  // <Document>
  //   <Page style={styles.body}>
  //     <Text style={styles.header} fixed>
  //       ~ www.123signature.com ~
  //     </Text>
  //     <Text style={styles.title}>QUITTANCE DE LOYER {values.jobType}</Text>
  //     <Text style={styles.author}>Bailleur: {values.firstName}</Text>
  //     <Text style={styles.subtitle}>Adresse du logement:</Text>
  //     <Text style={styles.text}>{values.address} </Text>
  //     <Text style={styles.text}>
  //       Je soussigné {values.firstName} propriétaire du logement désigné
  //       ci-dessus, déclare avoir reçu de {values.lastName} la somme de{" "}
  //       {values.rent} euros pour le mois de {values.jobType}
  //     </Text>
  //     <Text style={styles.text}>
  //       Fait le {new Date().toISOString().split("T")[0]}
  //     </Text>
  //     <Text style={styles.text}>Signature</Text>
  //     <Image style={styles.image} src={imageURL} />
  //     <Text style={styles.author}>
  //       Cette quittance annule tous les reçus qui auraient pu être établis
  //       précédemment en cas de paiement partiel du montant du présent terme.
  //       Elle est à conserver pendant trois ans par le locataire (article 7-1
  //       de la loi n° 89-462 du 6 juillet 1989).
  //     </Text>
  //     <Text
  //       style={styles.pageNumber}
  //       render={({ pageNumber, totalPages }) =>
  //         `${pageNumber} / ${totalPages}`
  //       }
  //       fixed
  //     />
  //   </Page>
  // </Document>
}
